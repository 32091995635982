
  import { ref, onMounted, onUnmounted } from 'vue';
  import Header from '../components/Headrer.vue';
  import MainTop from '../components/Main-Top.vue'
  import About from '../components/About.vue'
  import Projects from '../components/Projects.vue'
  import Contact from '../components/Contact.vue'
  import { ChevronDoubleUpIcon } from '@heroicons/vue/20/solid';
  
  export default {
    name: 'App',
    components: {
      Header,
      MainTop,
      About,
      Projects,
      Contact,
      ChevronDoubleUpIcon,
    },
    setup() {
      const isVisible = ref(false);
  
      const handleScroll = () => {
        isVisible.value = window.scrollY > 200;
      };
  
      onMounted(() => {
        window.addEventListener('scroll', handleScroll);
      });
  
      onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll);
      });
  
      const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
  
      return {
        isVisible,
        scrollToTop,
      };
    },
  };
  