import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import MainPage from '../views/MainPage.vue'
import About from '../components/About.vue'
import MainTop from '../components/Main-Top.vue'
import Projects from '../components/Projects.vue'
import Contacts from '../components/Contact.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage
  },
  {
    path: '/Header',
    name: 'Header',
    component: Headers
  },
  {
    path: '/About',
    name: 'About',
    component: About
  },
  {
    path: '/main',
    name: 'Main',
    component: MainTop
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contacts
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
