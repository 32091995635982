
import { ref } from 'vue';

export default {
  meta: {
    title: 'Fleetup Design | Your Web Development Destination',
    meta: [
      {
        name: 'Fleetup design',
        content: 'At Fleetup Design, we specialize in transforming your ideas into digital reality. With precision, passion, and creativity, we create functional websites that meet your business needs.'
      },
      {
        property: 'og:title',
        content: 'Fleetup Design'
      },
    ]
  },
  name: 'MainTop',
  setup() {
    const navigation = ref([
      { name: 'Contact Me', href: '#Contacts' },
    ]);

    return {
      navigation
    };
  },
};
