import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import "./main.css";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'aos/dist/aos.css';
import AOS from 'aos';

const firebaseConfig = {
    apiKey: "AIzaSyBJ44vZw-AwLVBChweeJgTX4jo9zAh8Qak",
    authDomain: "fleetup-search-ae193.firebaseapp.com",
    databaseURL: "https://fleetup-search-ae193.firebaseio.com",
    projectId: "fleetup-search-ae193",
    storageBucket: "fleetup-search-ae193.appspot.com",
    messagingSenderId: "571202548157",
    appId: "1:571202548157:web:c69cfe079bb28010c7e2d4"
};

// Check if firebase is already initialized
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

let app;

firebase.auth().onAuthStateChanged((user) => {
    if (user) {
        console.log('User is logged in');
        store.commit('setUser', user);
    }
    else {
        console.log('User is not logged in');
        store.commit('setUser', null);
    }
    if (!app) {
        app = createApp(App)
            .use(store)
            .use(router)
            .mount('#app');

        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            delay: 100,
        });
    }
});
