import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = {
  class: "main-top",
  id: "main-top"
}
const _hoisted_4 = {
  class: "about min-h-screen block",
  id: "About"
}
const _hoisted_5 = {
  class: "projects min-h-screen block",
  id: "Projects"
}
const _hoisted_6 = {
  class: "contact",
  id: "Contacts"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_MainTop = _resolveComponent("MainTop")!
  const _component_About = _resolveComponent("About")!
  const _component_Projects = _resolveComponent("Projects")!
  const _component_Contact = _resolveComponent("Contact")!
  const _component_ChevronDoubleUpIcon = _resolveComponent("ChevronDoubleUpIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_MainTop)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_About)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Projects)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_Contact)
    ]),
    _withDirectives(_createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.scrollToTop && $setup.scrollToTop(...args))),
      class: "flex items-center justify-center fixed bottom-4 right-4 md:bottom-8 md:right-8 z-50 bg-transparent text-white w-12 h-12 rounded-full hover:bg-transparent transition ease-in-out duration-300 focus:outline-none"
    }, [
      _createVNode(_component_ChevronDoubleUpIcon, { class: "animate-bounce h-10 w-10 text-red-500" })
    ], 512), [
      [_vShow, $setup.isVisible]
    ])
  ]))
}