import { createStore } from 'vuex';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default createStore({
  state: {
    user: null,
    loading: true,
  },
  getters: {
    getUser: (state) => state.user,
    isLoading: (state) => state.loading,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setLoading(state, value) {
      state.loading = value;
    },
  },
  actions: {},
  modules: {},
});
