
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
  
  export default {
    name: 'Login',
    setup() {
      const router = useRouter();
      const email = ref('');
      const password = ref('');
      const isLoading = ref(false);
      const error = ref<string | null>(null);
  
      const provider = new GoogleAuthProvider();
  
      async function signInWithGoogle() {
        const auth = getAuth();
        try {
          isLoading.value = true;
          const result = await signInWithPopup(auth, provider);
          const user = result.user;
  
          // After successful login, redirect to main page
          router.push('/');
        } catch (err: unknown) {
          if (err instanceof Error) {
            console.error(err.message);
            error.value = err.message;
          }
        } finally {
          isLoading.value = false;
        }
      }
  
      return {
        email,
        password,
        signInWithGoogle,
        isLoading,
        error,
      };
    },
  }
  