
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { getAuth, createUserWithEmailAndPassword, User as FirebaseUser, signInWithRedirect, GoogleAuthProvider } from 'firebase/auth';
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'SignUp',
  components: {
    EyeIcon,
    EyeSlashIcon
  },
  setup() {
    const router = useRouter();
    const user = ref<FirebaseUser | null>(null);
    const error = ref<string | null>(null);
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const password = ref('');
    const rePassword = ref('');
    const isLoading = ref(false);
    const showPassword = ref(false);
    const showRePassword = ref(false);

    const validateEmail = (email: string) => {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

    const signUp = async () => {
      if (password.value !== rePassword.value) {
        console.error('Passwords do not match');
        error.value = 'Passwords do not match';
        return;
      }

      if (!validateEmail(email.value.trim())) {
        console.error('Invalid email format');
        error.value = 'Invalid email format';
        return;
      }

      try {
        isLoading.value = true;
        const auth = getAuth();
        const res = await createUserWithEmailAndPassword(auth, email.value, password.value);
        error.value = null;
        user.value = res.user;
        router.push('/');
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.error('An error occurred while signing up');
          error.value = err.message;
        }
      } finally {
        isLoading.value = false;
      }
    };

    const signInWithGoogle = async () => {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      try {
        isLoading.value = true;
        await signInWithRedirect(auth, provider);
        // Result will be handled after redirection
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.error('An error occurred while signing in with Google');
          error.value = err.message;
        }
      } finally {
        isLoading.value = false;
      }
    };

    return {
      firstName,
      lastName,
      email,
      password,
      rePassword,
      signUp,
      signInWithGoogle,
      error,
      user,
      isLoading,
      showPassword,
      showRePassword,
    };
  },
}
