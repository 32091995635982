
import { ref, onMounted, onUnmounted, Ref } from 'vue';
import { Dialog, DialogPanel } from '@headlessui/vue';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';
import { auth, db } from '../firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

interface NavigationItem {
  name: string;
  href: string;
}

export default {
  components: {
    Dialog,
    DialogPanel,
    Bars3Icon,
    XMarkIcon,
  },
  setup() {
    const navigation: Ref<NavigationItem[]> = ref([
      { name: 'Home', href: '#' },
      { name: 'About', href: '#About' },
      { name: 'Projects', href: '#Projects' },
      { name: 'Contact', href: '#Contacts' },
    ]);

    const dropdownMenuRef: Ref<HTMLElement | null> = ref(null);

    const mobileMenuOpen: Ref<boolean> = ref(false);
    const showDropdown: Ref<boolean> = ref(false);

    const user: Ref<firebase.User | null> = ref(null);
    const userProfilePic: Ref<string | null> = ref(null);

    // Close the dropdown when clicking anywhere on the screen
    const closeDropdown = (event: Event) => {
      if (!dropdownMenuRef.value?.contains(event.target as Node)) {
        showDropdown.value = false;
      }
    };

    onMounted(() => {
      window.addEventListener('click', closeDropdown);

      // Fetch the user profile pic if the user is logged in
      const onAuthStateChangedUnsubscribe = auth.onAuthStateChanged((currentUser: firebase.User | null) => {
        user.value = currentUser;

        if (currentUser) {
          if (currentUser.providerData[0]?.providerId === 'google.com') {
            // The user logged in using Google, so use their Google profile pic
            userProfilePic.value = currentUser.photoURL;
          } else {
            // The user did not log in using Google, so fetch their profile pic from Firestore
            db.collection('users')
              .doc(currentUser.uid)
              .onSnapshot((doc) => {
                const userData = doc.data();
                userProfilePic.value = userData && userData.profilePic ? userData.profilePic : null;
              });
          }
        }
      });

      // Unsubscribe from the auth state change listener when component is unmounted
      onUnmounted(() => {
        onAuthStateChangedUnsubscribe();
        window.removeEventListener('click', closeDropdown);
      });
    });

    const signOut = async () => {
      try {
        await auth.signOut();
        window.location.href = '/login';
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };

    return {
      navigation,
      mobileMenuOpen,
      showDropdown,
      user,
      signOut,
      userProfilePic,
    };
  },
};
