import firebase from 'firebase/compat/app';  
import 'firebase/compat/auth';  
import 'firebase/compat/firestore'; 
import 'firebase/compat/storage';
import 'firebase/compat/functions';


const firebaseConfig = {
    apiKey: "AIzaSyBJ44vZw-AwLVBChweeJgTX4jo9zAh8Qak",
    authDomain: "fleetup-search-ae193.firebaseapp.com",
    databaseURL: "https://fleetup-search-ae193-default-rtdb.firebaseio.com",
    projectId: "fleetup-search-ae193",
    storageBucket: "fleetup-search-ae193.appspot.com",
    messagingSenderId: "571202548157",
    appId: "1:571202548157:web:ec28c0a6c18469d5c7e2d4"
  };

  firebase.initializeApp(firebaseConfig);
  const functions = firebase.functions();

  if (window.location.hostname === 'localhost') {
    firebase.firestore().useEmulator('localhost', 8080);
  }

  export const auth = firebase.auth();
  export const db = firebase.firestore();
  export const storage = firebase.storage();
  export default firebase;
  export { functions };
