
import { defineComponent, reactive, ref, watch } from 'vue';
import axios from 'axios';
import Notification from './Notification.vue'

export default defineComponent({
  components: { Notification },
  setup() {
    const name = ref('');
    const email = ref('');
    const description = ref('');
    const message = ref('');
    const notification = reactive({ show: false, message: '', success: false });
    const closeNotification = () => {
      notification.show = false;
    };

    const handleSubmit = async () => {
      try {
        const res = await axios.post('https://us-central1-fleetup-search-ae193.cloudfunctions.net/submitForm', {
          name: name.value,
          email: email.value,
          description: description.value,
          message: message.value,
        });

        console.log(res.data.result);

        name.value = '';
        email.value = '';
        description.value = '';
        message.value = '';

        console.log("Form submitted, triggering notification");
        notification.show = true;
        notification.message = 'Message sent successfully';
        notification.success = true;
      } catch (err) {
        console.error(err);
        console.log("Form submission failed, triggering notification");
        notification.show = true;
        notification.message = 'Message not sent, try again';
        notification.success = false;
      }
    };

    watch(() => notification.show, (newVal, oldVal) => {
      if (newVal) {
        setTimeout(() => {
          closeNotification();
        }, 3000); // Notification will disappear after 3 seconds
      }
    })

    return { name, email, description, message, handleSubmit, notification };
  },
});
